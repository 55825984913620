.layout {
    max-width: @max-width;
    margin: 0 auto;
    background-color: #f7f8fa;
    .nut-tabbar-fixed{
        z-index: 1;
        max-width: @max-width;
        margin: 0 auto;
        left: 0;
        right: 0;
        box-shadow: @btn-box-shadow;
    }
    .am-activity-indicator{
        justify-content: center;
    }
    .am-tabs-default-bar-tab{
        font-weight: bold;
        font-size: @font-size-16;
        color: @text-color;
        line-height: normal;
        text-align: center;
        &.am-tabs-default-bar-tab-active{
            color: @primary;
        }
    }
    
    .am-list-item .am-input-control input,.am-textarea-control textarea{
        font-size: @font-size-14;
    }
    .am-list-line{
        border-bottom: 0px !important;
        &::after{
            height: 0px !important;
            background-color: transparent !important;
        }
    }
}
:root{
    h1, h2, h3, h4, h5, h6{
        margin-top: 0;
        margin-bottom: 0.5em;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
    }

    h1{
        font-size: 2em;
    }
    h2{
        font-size: 1.5em;
    }
    h3{
        font-size: 1.17em;
    }
    h4{
        font-size: 14px;
    }
    h5{
        font-size: .83em;
    }
    h6{
        font-size: .67em;
    }
    //popup
    :not(.nut-popover) > .nut-popup{
        max-width: @max-width;
        margin: 0 auto;
        position: fixed;
        width: 100%;
        left: 0;
        right: 0;
    }
    .popup-main{
        padding: @margin-s @margin-m @margin-s;
    }

    //返回顶部
    .nut-backtop{
        bottom: 80px!important;
        box-shadow: @btn-box-shadow;
    }
    
    //collapase
    .nut-collapse-item{
        &:first-child{
            .nut-collapse-item-header{
                border-radius: 6px 6px 0 0;
            }
        }
        &:last-child{
            .nut-collapse-item-content{
                border-radius: 0 0 6px 6px ;
            }
        }
    }
    .nut-collapse-item-header{
        font-weight: 500;
        // border-radius: 6px 6px 0 0;
    }
    .nut-collapse-item-content{
        font-size: 14px;
        // border-radius: 0 0 6px 6px ;
    }

    //loading
    .nut-loading{
        display: flex;
    }

    //滚动列表
    .nut-infiniteloading .nut-infinite-bottom{
        margin-bottom: @margin-xs;
    }

    //form
    .nut-form-item-label{
        color: @h4;
    }

    .nut-input input::-webkit-input-placeholder{
        color:@status-text-gray-1;
        font-size: 13px;
    }
    .nut-textarea textarea::-webkit-input-placeholder{
        color:@status-text-gray-1;
        font-size: 13px;
    }

    //label在顶部的样式
    .form-layout-top{
        .nut-form-item-label{
            margin-bottom: @margin-s;
            padding: 0;
        }
        .select-box{
            border: 1px solid #E2E5E7;
            padding: 2px 4px;
            border-radius: 4px;
            min-height: 32px;
        }
    }

    //collapse
    .nut-collapse-item-icon-box{
        // width: 50px;
        color: @title-color;
    }
    .collapse-expand-icon{
        width: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
} 