:root{
    // --header_height: 48px;
    --header_height: 0;
}

// 公共变量
@navbar-height: 45px;
@primary: #2B33E6;
@secondary: #E3EDFA;
@qw-color: #346bbc;
@max-width: 580px;
@header-height: var(--header_height);
@tabbar-height: var(--nutui-tabbar-height, 60px);
@disabled: rgb(237, 240, 241);
// border-radius
@border-radius-2: 2px;
@border-radius-4: 4px;
@border-radius: 6px;
@border-radius-15: 15px;
@border-color: #E8F0F9;
@border-color-1: #f3f3f3;
//line height
@line-height: 1.85;
// box-shadow
@box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.05);
@btn-box-shadow: 0px 0px 8px -1px rgba(0, 0, 0, 0.11);
@success: #64A853;
@error: #CF1E1E;
@error-second: #FF4B4B;
@tips-color:#FF4C4C;
@gold: #DBAD77;
// bg
@bg:#FBFBFC;
@bg-1: #F3F3F3;
@bg-2: #F6F7F8;
@item-bg: #F6F7F8;
@header-bg: @primary;
// font color
@user: #BBCBD7;
@h5: #596A7A;
@text-color: #8C9FAD;
@text-color-gray: #1F3F59;
@text-color-disabled: #9CA9B5;
@text-color-light: rgba(255,255,255, .6);
@h4:#454545;
@title-color: #137bbb;
@title-color-disabled: #64a5ce;
// state
@state-blue-bg: #E3FAFF;
@state-blue-t: #51CFF7;
@state-dark-blue-t: #5786FF;
@state-dark-blue-bg: #38ADC7;
@state-gray-bg: #ebebeb;
@state-gray-t: #a3a3a3;
@status-text-gray-1: #cacbcd;
@status-text-gray-2: #7c7d80;
@state-yellow-bg: #F3D6B3;
@state-yellow-t: #EC932A;
@state-orange-t: #FFB257;
@state-green-bg: #C0F3B3;
@state-green-t: @success;
@state-light-green-t: #2BC961;
@state-purple-bg: #EFD6FF;
@state-purple-t: #BB4CFF;
@state-cyan-bg: #D7FFF5;
@state-cyan-t: #0EC597;
@state-vip-t:#D29A58;
@state-red-bg:#FFE2E2;
@state-red-t:@error-second;
@state-light-gray-t: #8C8C8C;
@state-light-gray-bg: #ECECEC;
@state-light-yellow-bg: #F3EDB3;
@state-light-yellow-t: #F4D119;
@state-light-yellow-tc: #EC932A;
// margin/padding
@margin-xxxl: 40px;
@margin-xxl: 32px;
@margin-xl: 24px;
@margin-l: 20px;
@margin-m: 16px;
@margin-s: 10px;
@margin-xs: 8px;
@margin-xxs: 4px;
// font size
@font-size-10: 10px;
@font-size-12: 12px;
@font-size-13: 13px;
@font-size-14: 14px;
@font-size-16: 16px;
@font-size-18: 18px;
@font-size-20: 20px;
@font-size-22: 22px;
@font-size-24: 24px;
@font-size-32: 32px;

@gray-color:#9CA9B5;
@primary-color: #2B33E6; // 主色调
@primary-second-color:#E3EDFA;//次色调
@qy-color: #4676C8;
@qy-border-color: #137BBB;
// each
@direction: {
    top: t;
    bottom: b;
    left: l;
    right:r ;
};
@text-colors: {
    status-text-h5: @h5;
    status-text-normal: @text-color;
    status-text-error: @error;
    status-text-error-tip: @tips-color;
    status-text-red: @state-red-t;
    status-text-light-red: red;
    status-text-orange: @state-orange-t;
    status-text-blue: @state-blue-t;
    status-text-dark-blue: @state-dark-blue-t;
    status-text-green: @state-green-t;
    status-text-light-green: @state-light-green-t;
    status-text-cyan: @state-cyan-t;
    status-text-gray: @state-gray-t;
    status-text-gray-1: @status-text-gray-1;
    status-text-gray-2:@status-text-gray-2;
    status-text-light-gray: @text-color-disabled;
    status-text-primary: @primary;
    status-text-primary-1: @title-color;
    status-text-yellow: @state-yellow-t;
    status-text-light-yellow: @state-light-yellow-t;
    status-text-gold:  @state-vip-t;
    status-text-purple: @state-purple-t;
    black: #000;
    black-1: #1a1a1a;
}

@btn-colors: {
    status-btn-red: @state-red-t, @state-red-bg;
    status-btn-orange: @state-yellow-t, @state-yellow-bg;
    status-btn-blue: @state-blue-t, @state-blue-bg;
    status-btn-dark-blue: @state-dark-blue-t, @state-blue-bg;
    status-btn-gradient-blue: #fff, #1BBEE2;
    status-btn-green: @state-green-t, @state-green-bg;
    status-btn-light-green: @state-light-green-t, @state-green-bg;
    status-btn-cyan: @state-cyan-t, @state-cyan-bg;
    status-btn-gray: @state-gray-t, @state-gray-bg;
    status-btn-dark-gray: #000, #d1d1d1;
    status-btn-light-gray: @state-light-gray-t, @state-light-gray-bg;
    status-btn-light-gray: @text-color, @bg-2;
    status-btn-h5-gray:  @h5,@item-bg;
    status-btn-blue-gray: #5C7A97, #F2F8FE;
    status-btn-primary: #fff, @primary;
    status-btn-secondary: @primary, @secondary;
    status-btn-light-yellow: @state-light-yellow-t, @state-light-yellow-bg;
    status-btn-purple: @state-purple-t, @state-purple-bg;
    status-btn-vip: @state-vip-t, #FFF5E7;
    status-btn-limited-free: #FF6767, #FFF9F2;
    status-btn-light-vip: #C49A6A, #FFF9F2;
    status-btn-dark-red:#fff,@tips-color;
    status-btn-dark-cyan:#fff,#3FCAB9;
    status-btn-dark-yellow:#fff,#DAC243;
    status-btn-light-blue: @state-blue-t, #E3FCFF;
    status-btn-competition-yellow: @state-light-yellow-tc, @state-light-yellow-bg;
    status-btn-text-yellow:#B8A22F,@state-light-yellow-bg;
    status-btn-opacity-blue:@state-dark-blue-t,rgba(87, 134, 255, 0.1);
}

@swipe-btn-colors: {
    swipe-btn-dark-red:#fff,@tips-color;
}
.transition (@type: all,@time: .3s){
    transition: @type @time cubic-bezier(0.7, 0.3, 0.1, 1);
    -moz-transition: @type @time cubic-bezier(0.7, 0.3, 0.1, 1); /* Firefox 4 */
    -webkit-transition: @type @time cubic-bezier(0.7, 0.3, 0.1, 1); /* Safari 和 Chrome */
    -o-transition: @type @time cubic-bezier(0.7, 0.3, 0.1, 1); /* Opera */
}

.rotate(@rotate: -20deg){
    transform: rotate(@rotate);
    -moz-transform: rotate(@rotate);
    -webkit-transform: rotate(@rotate);
    -o-transform: rotate(@rotate);
}

.gradient (@start:#7A88FF, @end:#7AFFAF) {
    background: -moz-linear-gradient(left, @start, @end);
    background: -webkit-linear-gradient(left, @start, @end);
    background: -o-linear-gradient(left, @start, @end);
    background: -ms-linear-gradient(left, @start, @end);
    background-image: linear-gradient(to right , @start, @end);
}
.gradient-bottom (@start:#7A88FF , @end:#7AFFAF) {
    background: -moz-linear-gradient(top, @start, @end);
    background: -webkit-linear-gradient(top, @start, @end);
    background: -o-linear-gradient(top, @start, @end);
    background: -ms-linear-gradient(top, @start, @end);
    background-image: linear-gradient(to bottom , @start, @end);
}

.overhidden-line (@n:2) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: @n;
    -moz-line-clamp: @n;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
}

.height-vh{
    height: -webkit-fill-available;
    height: -moz-fill-available;
    height: -moz-available;    /* FireFox目前这个生效 */
}

.width-fill{
    width: -webkit-fill-available;
    width: -moz-fill-available;
    width: -moz-available;    /* FireFox目前这个生效 */
}

.max-width-fill{
    max-width: -webkit-fill-available;
    max-width: -moz-fill-available;
    max-width: -moz-available;    /* FireFox目前这个生效 */
    // width: fill-available;
}

.position-fixed{
    position: fixed;
    width: 100%;
    max-width: @max-width;
    margin: 0 auto;
    left: 0;
    right: 0;
}
.postion-absolute{
    position: absolute;
    width: 100%;
    max-width: @max-width;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.text-break {
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

.pre, pre{
    font-family: PingFang SC, Helvetica Neue, Helvetica, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
    margin-bottom: 0;
    white-space: pre-wrap;
    /*css-3*/
    white-space: -moz-pre-wrap;
    /*Mozilla,since1999*/
    white-space: -pre-wrap;
    /*Opera4-6*/
    white-space: -o-pre-wrap;
    /*Opera7*/
    word-wrap: break-word;
    /*InternetExplorer5.5+*/
    overflow-wrap: break-word;
    /*与word-wrap相同的作用，word-wrap被重命名为 overflow-wrap*/
}

.space-line-width(@w: 16px) {
    width: 100%;
    height: (@w);
    background-color: @item-bg;
}
.page-result{
    margin-top: 70px;
    background-color: #fff;
    justify-content: center;
    .icon404{
        width: 150px;
    }
    .page-status-text{
        margin: @margin-xs 0 @margin-xxxl;
        color: @text-color;
    }
}

@brand-primary: #2B33E6;@fill-disabled: #8C9FAD;@fill-mask: rgba(0, 0, 0, 0.18);@disabled-color: #596A7A;@brand-success: #64A853;@brand-error: #CF1E1E;@border-color-base: #E2E5E7;@font-size-base: 13px;@font-size-lg: 13px;@select-multiple-item-height-lg: 22px;@search-bar-font-size: 13px;@search-bar-height: 38px;@search-bar-input-height: 34px;