
@font-face{
    font-family: 'BiaoTiHei';
    src: url('https://static.acecamptech.com/system/font/UISDC-BiaoTiHei.eot');
    src:  url('https://static.acecamptech.com/system/font/UISDC-BiaoTiHei.eot?#iefix') format('embedded-opentype'),
    url('https://static.acecamptech.com/system/font/UISDC-BiaoTiHei.woff2') format('woff2'), 
    url('https://static.acecamptech.com/system/font/UISDC-BiaoTiHei.woff') format('woff'),
    url('https://static.acecamptech.com/system/font/UISDC-BiaoTiHei.ttf') format('truetype'); 
    /*
    url('https://static.acecamptech.com/system/font/ax2n7-c70bf.svg') format('svg'); */
    font-weight: normal;
    font-style: normal;
}

.font-BiaoTiHei{
    font-family: 'BiaoTiHei';
}
@brand-primary: #2B33E6;@fill-disabled: #8C9FAD;@fill-mask: rgba(0, 0, 0, 0.18);@disabled-color: #596A7A;@brand-success: #64A853;@brand-error: #CF1E1E;@border-color-base: #E2E5E7;@font-size-base: 13px;@font-size-lg: 13px;@select-multiple-item-height-lg: 22px;@search-bar-font-size: 13px;@search-bar-height: 38px;@search-bar-input-height: 34px;