.ql-container{
    border: none !important;
    height: auto;
    font-size: @font-size-16;
    .ql-editor{
        padding: 0;
        line-height: 2.0;
        color: #303030;
        // letter-spacing: .06em;
        ul,
        ol {
            padding-left: 0;
            padding-inline-start: @margin-m;
    
            li {
                line-height: 1.75;
                padding-bottom: @margin-xs;
    
                &:not(.ql-direction-rtl) {
                    padding-left: @margin-xxs;
                }
            }
        }
    
        ol {
            padding-inline-start: 15px;
    
            >li {
                list-style-type: decimal;
    
                &:before {
                    display: none;
                }
            }
        }

        ul,
        ol {
            .qlindent(@i) when(@i <= 10) {
                .ql-indent-@{i} {
                    &:not(.ql-direction-rtl) {
                        padding-left: 1.5em * @i;
                    }
                    .ql-direction-rtl {
                        .ql-align-right {
                            padding-right: 1.5em * @i;
                        }
                    }
                }
                .qlindent(@i + 1);
            }
            .qlindent(1);
        }

        p{
            margin-bottom: 0;
            // padding-bottom: @margin-m;
        }
        h1,h2,h3{
            // padding-bottom: @margin-m;
            line-height: normal;
        }
        .mention{
            background: @secondary;
            margin-right: @margin-xs;
        }
        img{
            padding: @margin-m 0;
            width: 100% !important;
            height: 100% !important;
        }
        p > img {
            padding: 0;
        }
        .ql-size-small{
            font-size: @font-size-14;
        }
        .ql-size-large{
            font-size: @font-size-18;
        }
        .ql-emojiblot{
            display: inline-block;
            width: 20px;
            vertical-align: middle;
        }
        .divider{
            position: relative;
            font-size: 14px;
            font-weight: bold;
            font-style: italic;
            // letter-spacing: .08em;
            &:before {
                @height: 28px;
                @width: 100px;
                position: absolute;
                left: calc(50% - (@width/2));
                top: calc(-@height/2);
                display: flex;
                align-items: center;
                justify-content: center;
                height: @height;
                width: @width;
                content: attr(data-content);
                color: @primary;
                background: #fff;
                white-space: nowrap;
            }
        }
    }
}