:root,
page {
    // --nutui-color-primary: @primary;
    --nutui-color-primary: @title-color;
    --nutui-color-primary-stop-1: @title-color;
    --nutui-color-primary-stop-2: @title-color;
    --nutui-color-primary-light: @title-color;

    //col
    --nutui-col-default-margin-bottom: @margin-xs;

    //tabbar
    --nutui-tabbar-height: 60px;

    //form
    --nutui-form-item-required-color: #fa2c19;
    --nutui-form-item-error-message-color: #fa2c19;

    //button
    --nutui-button-primary-disabled: @disabled;
    --nutui-button-mini-border-radius: 6px;
    --nutui-button-normal-padding: 0 @margin-xs;
    --nutui-button-border-radius: 12px;
    --nutui-button-large-border-radius: 12px;
    --nutui-button-small-border-radius: 12px;
    --nutui-button-primary-disabled: #a0c0d3;

    //文本省略展开收起
    --nutui-ellipsis-expand-collapse-color: @title-color;

    //text-area
    --nutui-textarea-padding: @margin-xs @margin-s;

    //step
    --nutui-steps-base-icon-width: 20px;
    --nutui-steps-base-icon-height: 20px;
    --nutui-steps-base-icon-line-height: 20px;
    --nutui-steps-base-icon-margin-bottom: @margin-s;

    //navbar
    --nutui-navbar-background: #fff;
    --nutui-navbar-height: @navbar-height;
}