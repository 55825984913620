@font-face {
  font-family: "iconfont"; /* Project id 4704509 */
  src: url('iconfont.woff2?t=1735278007398') format('woff2'),
       url('iconfont.woff?t=1735278007398') format('woff'),
       url('iconfont.ttf?t=1735278007398') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-error-circle:before {
  content: "\e722";
}

.icon-check-circle1:before {
  content: "\e723";
}

.icon-info-circle:before {
  content: "\e721";
}

.icon--confirmed:before {
  content: "\e703";
}

.icon-starfill:before {
  content: "\e67e";
}

.icon-tishi:before {
  content: "\e617";
}

.icon--attention:before {
  content: "\e702";
}

.icon-wenjian:before {
  content: "\e64e";
}

.icon-arrow:before {
  content: "\e61c";
}

.icon-chat:before {
  content: "\e61d";
}

.icon-Ai:before {
  content: "\e8a7";
}

.icon-call_out:before {
  content: "\e706";
}

.icon-add:before {
  content: "\e643";
}

.icon-close:before {
  content: "\e605";
}

.icon-qingchu:before {
  content: "\e713";
}

.icon-copy:before {
  content: "\e61b";
}

.icon-shouqi:before {
  content: "\e6b8";
}

.icon-zhankai:before {
  content: "\e62d";
}

.icon-filter:before {
  content: "\e839";
}

.icon-ongoing:before {
  content: "\e60a";
}

.icon-duihao:before {
  content: "\eaf1";
}

.icon-dui:before {
  content: "\e60d";
}

.icon-arrow-up-fill:before {
  content: "\e604";
}

.icon-search:before {
  content: "\e6bc";
}

.icon-arrow-down-fill:before {
  content: "\e603";
}

.icon-icon-test:before {
  content: "\e704";
}

.icon-xuqiuguanli:before {
  content: "\e860";
}

.icon-xinjianwendang:before {
  content: "\e62c";
}

.icon-xinjian:before {
  content: "\e609";
}

.icon-xuqiu-hui:before {
  content: "\e60b";
}

.icon-zhuanjiaku_tuandui:before {
  content: "\e79f";
}


@brand-primary: #2B33E6;@fill-disabled: #8C9FAD;@fill-mask: rgba(0, 0, 0, 0.18);@disabled-color: #596A7A;@brand-success: #64A853;@brand-error: #CF1E1E;@border-color-base: #E2E5E7;@font-size-base: 13px;@font-size-lg: 13px;@select-multiple-item-height-lg: 22px;@search-bar-font-size: 13px;@search-bar-height: 38px;@search-bar-input-height: 34px;