:root{
    height: 100%;
    .page-warpper{
        margin: 0 @margin-m 0;
    }

    .page-warpper-pc{
        max-width: @max-width;
        margin: 0 auto;
    }

    .flex{
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: -moz-box;
        display: -moz-flex;
        display: flex;
    }

    .flex-col{
        .flex();
        flex-direction: column;
    }

    .flex-ac{
        .flex();
        align-items: center;
    }

    .flex-as{
        .flex();
        align-items: flex-start;
    }

    .flex-jsb{
        .flex();
        justify-content: space-between;
    }

    .flex-ac-jsb{
        .flex-ac();
        justify-content: space-between;
    }

    .flex-as-jsb{
        .flex-as();
        justify-content: space-between;
    }

    .flex-ac-je{
        .flex-ac();
        justify-content: flex-end;
    }
    .flex-ac-jc{
        .flex-ac();
        justify-content: center;
    }
    .flex-ad-jc {
        .flex();
        align-items: flex-end;
        justify-content: center;
    }
    .flex-w{
        .flex();
        flex-wrap: wrap;
    }

    .flex-inline{
        display: inline-flex;
    }

    .flex-1{
        flex: 1;
    }

    .flex1{
        flex: 1;
        min-width: 0;
        width: 0;
    }

    .grid-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: @margin-m;
    }

    .hide{
        display: none !important;
    }

    .nowrap{
        white-space: nowrap;
    }

    .text-bold{
        font-weight: bold;
    }

    .text-center{
        text-align: center;
    }
    .text-right{
        text-align: right;
    }

    .btn-default{
        border: none;
        border-radius: @border-radius!important;
        background-color: @title-color;
        color: #fff;
    }

    .btn-cancel{
        background: @state-gray-bg;
        color: @text-color-gray;
        border-color: @state-gray-bg;
        &:hover, &:focus{
            color: @text-color-gray;
            opacity: .9;
            background: @state-gray-bg;
            border-color: @state-gray-bg;
        }
    }

    .btn-cancel-ghost{
        color: #000;
        border: 1px solid @border-color-1 !important;
    }

    /** 超出省略 */
    .overhidden {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .no-wrap{
        white-space: nowrap;
    }
    .w-100{
        width: 100%;
    }
    .omit-1{
        .overhidden-line(1);
        display: revert;
    }
    .omit-2{
        .overhidden-line(2)
    }
    .omit-3{
        .overhidden-line(3)
    }
    .overflow-scroll-3{
        white-space: nowrap;
    }

    .box{
        margin-bottom: @margin-m;
        padding: @margin-l @margin-l;
        // box-shadow: @box-shadow;
        background: #fff;
        border-radius: 2px;
    }

    .primary{
        color: @primary;
    }

    a, .link{
        color: @primary;
        cursor: pointer;
        &:hover{
            color: @primary;
            opacity: .85;
        }
    }

//text
    each(@text-colors, {
        .@{key} {
            color: @value;
            &:hover, &:focus{
                color: @value;
            }
        }
    });

    .status-text-bg-vip{
        background-image:-webkit-linear-gradient(180deg, #DFBC94 -27.63%, #DFBC94 -27.61%, #FFEAD1 123.89%);
        -webkit-background-clip:text;
        -webkit-text-fill-color:transparent;
    }

// btn
    each(@btn-colors, .(@color, @color-key, @color-index){
        .@{color-key} {
            color: extract(@color, 1);
            padding: 2px @margin-xs;
            background: extract(@color, 2);
            border: 1px solid extract(@color, 2);
            border-radius: 2px;
            font-weight: normal;
            &:hover, &:focus{
                background: extract(@color, 2);
                color: extract(@color, 1);
            }
        }
        a:not(.am-button).@{color-key}{
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    });

    .status-btn-vip{
        .gradient(#FFE6BF, #FFF5E7) !important;
    }

    .status-btn-gradient-blue{
        .gradient(#2677F0, #1BBEE2) !important;
    }

    .status-btn-light-vip{
        border-color: #C49A6A;
        padding: 0 @margin-xxs;
        margin-right: @margin-xxs;
        font-weight: normal;
        font-size: @font-size-14;
    }

    .status-btn-limited-free {
        color: #FF6767;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        justify-content: center;
        border: 1px solid #FF6767;
        padding: 0 @margin-xxs !important;
        margin-right: @margin-xxs;
        font-weight: normal;
        font-size: @font-size-12;
        border-radius: 2px;
    }

    .ghost{
        background: transparent;
        border: 1px solid;
    }

    .status-small{
        display: inline-flex;
        padding: 0 @margin-xxs;
        line-height: normal;
        font-size: @font-size-10;
        white-space: nowrap;
    }


//tag
    .nut-tag.status-red-tag{
        border: 1px solid #ffa39e;
        color: #cf1322;
        background-color: #fff1f0;
    }
    .nut-tag.status-yellow-tag{
        border: 1px solid #ffd591;
        color: #d46b08;
        background-color: #fff7e6;
    }

// @swipe-btn
    each(@swipe-btn-colors, .(@color, @color-key, @color-index){
        .@{color-key} {
            color: extract(@color, 1);
            background: extract(@color, 2);
            &:hover, &:focus{
                background: extract(@color, 2);
                color: extract(@color, 1);
            }
        }
    });
// 字体
    .font(@i) when(@i <= 28){
        .fz-@{i} {
            font-size: @i + 0px ;
        }
        .font((@i + 2));
    }

    .font(10);

    .fz-13{
        font-size: 13px;
    }
// 边距
    // margin
    each(@direction, {
        .margin(@i) when(@i <= 28){
            .m@{value}-@{i} {
                margin-@{key}: @i + 0px;
            }
            .margin((@i + 2));
        }
        .margin(-2)
    });
    // padding
    each(@direction, {
        .padding(@i) when(@i <= 28){
            .p@{value}-@{i} {
                padding-@{key}: @i + 0px;
            }
            .padding((@i + 2));
        }
        .padding(-2)
    });


    .space-line-5 {
        .space-line-width(5px)
    }

    .space-line-50 {
        .space-line-width(50px)
    }
    .space-line-57 {
        .space-line-width(57px)
    }

    .input-group{
        border: 1px solid @bg;
        border-radius: @border-radius;
        .ant-input{
            border-radius: 0 @border-radius @border-radius 0 !important;
        }
        .search-select{
            min-width: 80px;
            .ant-select-selector{
                height: 38px;
            }
        }
        .search-input{
            padding-left: 0;
            border: 0;
        }
    }

    .fixed-bottom{
        .position-fixed();
        left: 0;
        bottom: 0;
        text-align: center;
        background: #fff;
        box-shadow: @btn-box-shadow;
        font-size: @font-size-12;
        font-weight: bold;
        .btn{
            display: inline-block;
            flex: 1;
            padding: @margin-m 0;
            &.btn-primary{
                background: @primary;
                color: #fff;
            }
        }
    }

    .fixed-bottom-20{
        .position-fixed();
        left: 0;
        bottom: 0;
        padding-bottom: 20px;
        text-align: center;
        font-size: @font-size-12;
        z-index: 1;
        background: rgba(255,255,255, .92);
        .btn{
            flex: 1;
            margin-left: @margin-m;
            border-radius: @border-radius-4;
            &:last-child{
                margin-right: @margin-m;
            }
        }
        .notice{
            margin: 0 @margin-m;
            padding: @margin-xs 0 @margin-xs;
            color: @state-orange-t;
            text-align: center;
            font-size: @font-size-12;
            line-height: normal;
        }
    }

    .fixed-btn{
        .position-fixed();
        bottom: 26px;
        .flex-ac-jc();
        z-index: 2;
        .am-button{
            min-width: 90px;
            border-radius: @border-radius-15;
        }
    }

    .sticky-top{
        position: sticky;
        left: 0;
        top: @header-height;
        z-index: 2;
        background: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    }
    .sticky-bottom{
        position: sticky;
        left: 0;
        bottom: 0;
        text-align: center;
        background: #fff;
        width: 100%;
        box-shadow: @btn-box-shadow;
        font-size: @font-size-12;
        font-weight: bold;
        z-index: 2;
        .btn{
            display: inline-block;
            flex: 1;
            padding: @margin-m 0;
            &.btn-primary{
                background: @primary;
                color: #fff;
            }
        }
    }

    .fixed-notice-bottom{
        .position-fixed();
        left: 0;
        bottom: 47px;
        padding: @margin-xs 0;
        text-align: center;
        font-size: @font-size-12;
        font-weight: bold;
        color: @h5;
        background: #FFFBD8;
    }

    .bg-block{
        margin-bottom: @margin-s;
        background: @bg;
        padding: @margin-s @margin-xs;
        font-size: @font-size-12;
        font-weight: bold;
        color: @primary;
        // line-height: 1.35;
        border: 1px solid @bg;
        .text-break();
        &:last-child{
            margin-bottom: 0;
        }
        &.dis-handle{
            color: @text-color;
            opacity: .9;
        }
    }

    .placeholder{
        color: @text-color;
    }

    // 页面右侧的小图标 eg: 点赞，回首页等
    .fixed-right-btn{
        position: fixed;
        right: @margin-m;
        bottom: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        line-height: normal;
        box-shadow: @box-shadow;
        opacity: .95;
        z-index: 99;
        font-size: @font-size-10;
        .iconfont{
            margin-right: 0;
            font-size: @font-size-20;
        }
    }

    .tabs{
        border-bottom: 1px solid @border-color;
        font-weight: bold;
        .tab-item{
            display: block;
            position: relative;
            padding: @margin-xs 0;
            text-align: center;
            color: @text-color-disabled;
            font-size: 15px;
            flex: 1;
            width: 0;
            min-width: 0;
            &.active{
                color: @primary;
                &:after{
                    position: absolute;
                    left: 0;
                    bottom: -1px;
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: @primary;
                }
            }
        }
    }
    .idea-positive,.idea-cautious{
        display: inline;
        font-weight: 800;
        font-size: @font-size-16;
        font-style: oblique;
    }
    .idea-positive{
        color: @error;
    }
    .idea-cautious{
        color: @success;
    }

    // 禁止复制
    .no-select {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .select {
        -webkit-touch-callout: text;
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }

    .race-img{
        margin-left: @margin-xxs;
        vertical-align: text-bottom;
    }
    //锁定样式
    .lock-invisible{
        background: rgba(255, 255, 255, .5);
        filter: blur(5px);
        &.expected-trend{
            background: @text-color-disabled;
            border-color: @text-color-disabled;
        }
    }
    //次卡支付样式
    .ppv-pay-btn{
        margin-right: @margin-m;
        .wait-time{
            background: rgba(87, 134, 255, 0.14);
            border-radius: 20px 20px 0px 0px;
            color: #5786FF;
            padding: @margin-xxs @margin-s;
            font-size: @font-size-12;
        }
        .status-btn-disabled{
            border: 1px solid #C6D0D9;
            background: #F6F7F8;
            color: #C6D0D9;
        }
    }
    //升级vip弹窗
    .upgrade-vip-modal{
        .status{
            text-align: center;
            border: none;
            padding: @margin-s;
            font-weight: bold;
        }
        .status-btn-opacity-blue{
            margin-top: -@margin-s;
        }
        .tips{
            color: @h5;
            margin: @margin-m 0 @margin-s 0;
        }
        .am-modal-title{
            text-align: left;
        }
    }

    .box-shadow{
        box-shadow: @box-shadow;
    }
    //可以点击
    .text-clickable{
        color: @title-color;
    }

    //兼容不同浏览器导航条
    .safe-bottom-gap{
        margin-bottom: 70px;
    }

    .break-all{
        word-break: break-all;
    }
    .overlay-close-click-events{
        pointer-events: none;
    }

    .popup-main-max70{
        min-height: 20vh;
        max-height: 70vh;
        overflow-y: scroll;
        background-color: #f7f8fa;
    }
    
}
